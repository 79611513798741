<template>
  <div>
    <div class="card store-small-card">
      <div class="card-image">
        <figure class="image is-2by1 m-0">
          <image-loader :id="image" max-width="100%" />
        </figure>
      </div>
      <div class="card-content is-flex is-flex-direction-column">
        <div v-if="icon" class="store-small-card__icon">
          <image-loader :id="icon" />
        </div>
        <span class="is-size-4 has-text-weight-bold store-small-card__title">{{
          merchantName
        }}</span>
        <span class="is-size-6 has-text-weight-light store-small-card__label">{{
          label
        }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "StoreSmallCard",
  props: {
    icon: {
      type: String,
    },
    merchantName: {
      type: String,
      required: true,
    },
    label: {
      type: String,
    },
    image: {
      type: String,
    },
  },
  components: {
    ImageLoader: () => import("@/components/document/ImageLoader.vue"),
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/colors.scss";

.store-small-card {
  border-radius: 24px;
  overflow: hidden;

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: -50px;
    width: 60px;
    height: 60px;
    background-color: white;
    border: 7px solid white;
    border-radius: 50%;
    z-index: 1;
  }
}
</style>
